import React from "react";
import { Link } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";
import "../asstes/styles/about.scss";
// Import local images
import bhupendraImage from "../asstes/images/about/bhupendra.png";
import philosopher from "../asstes/images/about/philosopher.png";
import NewsLetter from "./ReusableComponent/NewsLetter";
function About() {
  return (
    <div className="exce-about-us">
      <section className="page-banner banner">
        <Container>
          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                About
              </li>
            </ol>
          </nav>
          <Row className="align-items-end">
            <Col xs={12} md={6} lg={6} className="me-auto">
              <div className="about-banner-content">
                <span className="sub-title">
                  Bhupendra’s Corporate Trainings
                </span>
                <h1 className="banner-title">
                  A Man on a Mission To make
                  <span className="text-orange">life meaningful</span>
                </h1>
              </div>
            </Col>
            <Col xs={12} md={5} lg={5} className="ms-auto">
              <div className="bh-profile-banner">
                <img src={bhupendraImage} alt="bhupendraImage" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bg-white pd-common about-desc-sec">
        <Container>
          <Row>
            <Col xs={12} md={9} lg={9} className="mx-auto">
              <div className="details-desc">
                <h2 className="qute-msg-title">
                  “Infinite Mind is a gift to all,” said a visionary leader
                  blending science and spirituality. This insight encourages
                  exploration of our consciousness, fostering understanding of
                  ourselves and the universe. By integrating knowledge with
                  wisdom, we unlock our potential and enhance our experience.
                </h2>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bh-journey-sec pd-common bg-dark-b">
        <Container>
          <Row>
            <Col xs={12} md={8} lg={6} className="mx-auto">
              <div className="title-sec mb-5">
                <h2 className="title-heading text-white banner-title">
                  Bhupendra Chaudhary’s Journey
                </h2>
              </div>
            </Col>
            <Col xs={12} md={9} lg={9} className="mx-auto">
              <ul className="journey-timeline">
                <li>
                  <div className="timeline-content">
                    <span className="timeline__no">01</span>
                    <div className="timeline__content">
                      <h2 className="timeline__title">The Start</h2>
                      <p className="timeline__desc">
                        Sri Bhupendra began his journey with an unwavering
                        passion for excellence and a vision to create meaningful
                        impact. As a natural leader and visionary, he cultivated
                        the skills and determination needed to thrive in any
                        business environment.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="timeline-content">
                    <span className="timeline__no">02</span>
                    <div className="timeline__content">
                      <h2 className="timeline__title">The Growth</h2>
                      <p className="timeline__desc">
                        Sri Bhupendra began his journey with an unwavering
                        passion for excellence and a vision to create meaningful
                        impact. As a natural leader and visionary, he cultivated
                        the skills and determination needed to thrive in any
                        business environment.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="timeline-content">
                    <span className="timeline__no">03</span>
                    <div className="timeline__content">
                      <h2 className="timeline__title">The Initiatives</h2>
                      <p className="timeline__desc">
                        Sri Bhupendra began his journey with an unwavering
                        passion for excellence and a vision to create meaningful
                        impact. As a natural leader and visionary, he cultivated
                        the skills and determination needed to thrive in any
                        business environment.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="timeline-content">
                    <span className="timeline__no">04</span>
                    <div className="timeline__content">
                      <h2 className="timeline__title">Research</h2>
                      <p className="timeline__desc">
                        Sri Bhupendra began his journey with an unwavering
                        passion for excellence and a vision to create meaningful
                        impact. As a natural leader and visionary, he cultivated
                        the skills and determination needed to thrive in any
                        business environment.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bh-meta-awakening about-bh-sec bg-book-banner  pd-common">
        <Container>
          <div className="meta-awakening-desc">
            <p>THE BOOKS BY  </p>
          </div>
        </Container>
        <div className="title-sec">
          <h2 className="title-heading">Bhupendra Chaudhary’s </h2>
        </div>
      </section>
      <section className="meet-philosopher-sec pd-common bg-dark-b">
        <Container>
          <Row>
            <Col xs={12} md={8} lg={7} className="p-relative outer-banner-title-exce">
              <div className="title-sec mb-5">
                <h2 className="title-heading text-white title-left banner-title">
                  Meet Philosopher - Scientist, Sri Bhupendra
                </h2>
              </div>
            </Col>
          </Row>
          <Row className="gy-3">
            <Col xs={12} md={6} lg={6} className="exce-philosopher-img-outer">
              <div className="philosopher-img">
                <img src={philosopher} alt="philosopher" />
              </div>
            </Col>
            <Col xs={12} md={4} lg={4} className="mx-auto exce-philosopher-img-outer">
              <div className="philosopher-book-list">
                <span className="philosopher__no">01</span>
                <h3 className="philosopher__title">
                  Decades of Research and Global Experience
                </h3>
                <p className="philosopher__desc">
                  Sri Bhupendra has dedicated the last few decades to studying
                  the science of spirituality, consciousness, and
                  transformational principles. His global travels have exposed
                  him to cross-cultural challenges, inspiring active
                  participation in social and leadership programs at esteemed
                  institutions like Cambridge University, Harvard Business
                  School, and Stanford University. These experiences have
                  equipped him with research-based tools to address fundamental
                  human challenges effectively.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <NewsLetter />
    </div>
  );
}

export default About;
