import book1 from "../asstes/images/books/book-1.png";
import book2 from "../asstes/images/books/book-2.png";
import book3 from "../asstes/images/books/book-3.png";
import book4 from "../asstes/images/books/book-4.png";
import book5 from "../asstes/images/books/book-5.png";
import book6 from "../asstes/images/books/book-6.png";
import book7 from "../asstes/images/books/book-7.png";

const data = [
  {
    img: book1,
    title: "The Secret of Success",
    author: "Robin Sharma",
    country: "India",
    original_price: 500,
    offer_price: 350,
    link: "https://example.com/book1"
  },
  {
    img: book2,
    title: "Atomic Habits",
    author: "James Clear",
    country: "UnitedStates",
    original_price: 30,
    offer_price: 20,
    link: "https://example.com/book2"
  },
  {
    img: book3,
    title: "Think and Grow Rich",
    author: "Napoleon Hill",
    country: "India",
    original_price: 400,
    offer_price: 300,
    link: "https://example.com/book3"
  },
  {
    img: book4,
    title: "The Alchemist",
    author: "Paulo Coelho",
    country: "UnitedStates",
    original_price: 25,
    offer_price: 18,
    link: "https://example.com/book4"
  },
  {
    img: book5,
    title: "Ikigai",
    author: "Francesc Miralles",
    country: "India",
    original_price: 350,
    offer_price: 250,
    link: "https://example.com/book5"
  },
  {
    img: book6,
    title: "Rich Dad Poor Dad",
    author: "Robert Kiyosaki",
    country: "UnitedStates",
    original_price: 40,
    offer_price: 28,
    link: "https://example.com/book6"
  },
  {
    img: book7,
    title: "You Can Win",
    author: "Shiv Khera",
    country: "India",
    original_price: 300,
    offer_price: 200,
    link: "https://example.com/book7"
  }
]

export default data