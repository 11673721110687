import React from 'react'
import "../asstes/styles/contactUsPage.scss"
import login_banner from "../asstes/images/login-banner.png"

// -------------------------------------------

const ContactUsPage = () => {
  return (
    <>
      <div className='exce-contactUs-page'>
        <div className='exce-contactUs-page__inner'>
          <div className='contact-upper-section'>
            <div className='upper-section-left-img-div'>
              <img src={login_banner} alt="Contact img" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUsPage
