import React, { useEffect } from 'react'
import { Container, Row, Col } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import migros from "../../asstes/images/migros.png";
import denner from "../../asstes/images/denner.png";
import helsana from "../../asstes/images/helsala.png";
import feldschlosschen from "../../asstes/images/feldsche.png";
import cocacolahbc from "../../asstes/images/coca-cola.png";
import redbull from "../../asstes/images/redbull.png";
import swiss from "../../asstes/images/swiss.png";
import geberit from "../../asstes/images/geberit.png";
import tal from "../../asstes/images/tal.png";
import generali from "../../asstes/images/general.png";
import lyrics from "../../asstes/images/lyrsics.png";
import gamed from "../../asstes/images/gamed.png";

// ----------------------------------------------

const HomePageTogetherSection = () => {

  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 1200, // Animation duration (ms)
      easing: "ease-in-out", // Smooth animation effect
      once: true
    });
    // eslint-disable-next-line
  }, []);

  const logos = [
    { src: migros, alt: "migros" },
    { src: denner, alt: "denner" },
    { src: helsana, alt: "helsana" },
    { src: feldschlosschen, alt: "feldschlosschen" },
    { src: cocacolahbc, alt: "Coca Cola HBC" },
    { src: redbull, alt: "Red Bull" },
    { src: swiss, alt: "swiss" },
    { src: geberit, alt: "geberit" },
    { src: tal, alt: "tal" },
    { src: generali, alt: "generali" },
    { src: lyrics, alt: "lyrics" },
    { src: gamed, alt: "gamed" },
  ];

  return (
    <>
      <section className="bh-together pd-common bg-dark-b exce-together-section" >
        <Container>
          <Row>
            <Col xs={12} md={8} lg={6} className="mx-auto">
              <div className="title-sec" data-aos="fade-up">
                <h2 className="title-heading text-white">
                  Together we are achieving great things
                </h2>
              </div>
            </Col>
          </Row>
          <Row className="g-2 item-hover">
            {logos.map((logo, index) => (
              <Col
                key={index}
                xs={4}
                md={3}
                lg={3}
                xl={2}
                data-aos="fade-up"
                data-aos-delay={index * 100} // Adds a slight delay to each column
              >
                <div className="services-box">
                  <img src={logo.src} alt={logo.alt} />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section >

    </>
  )
}

export default HomePageTogetherSection
