import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import "../../asstes/styles/newsLetter.scss"

// ---------------------------------------

const NewsLetter = () => {

  const handleClick = () => {
    console.log("News Letter")
  }
  return (
    <>
      <section className="subscribe-sec pd-common subscribe-banner">
        <Container>
          <Row>
            <Col xs={12} md={8} lg={8} xl={8} className="mx-auto">
              <div className="subscribe-dt">
                <div className="title-sec mb-4">
                  <h2 className="title-heading text-white exce-newsletter-heading">
                    Get More of What You <br />
                    Love Subscribe Now....
                  </h2>
                </div>
                <form className="subscribe-form">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter your email address"
                  />
                  <button onClick={handleClick} className="btn btn-submit bh-btn-dark">Submit</button>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default NewsLetter
