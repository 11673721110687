import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import "../asstes/styles/events.scss";
import "../asstes/styles/newsPage.scss"
import AboutPhotoShoot from "../asstes/images/about-photoshoot.jpg";
import NewsLetter from "./ReusableComponent/NewsLetter";

// -------------------------------------

const InTheNewsPage = () => {

  return (
    <>
      <div className="exce-news-page">
        <section className="page-banner banner podcast-banner banner-two">
          <Container>
            <nav aria-label="breadcrumb" className="breadcrumb-nav">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  In The News
                </li>
              </ol>
            </nav>
            <Row>
              <Col xs={12} md={8} lg={8} className="me-auto">
                <div className="about-banner-content">
                  <h1 className="banner-title">News</h1>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="event-booking-sec pd-common bg-dark-b">
          <Container>
            <div className="events-tab-content">
              <div className="row gy-4">
                <div className="col-lg-4 col-md-4 col-sm-6 exce-news-card-col" >
                  <div className="bh-event-card">
                    <div className="bh-event-image">
                      <img
                        src={AboutPhotoShoot}
                        alt="..."
                      />
                      {/* <div className="event-dt">
                        <div className="event-meta-tag">
                          <button className="event-badge wealth-btn">Category</button>
                        </div>
                      </div> */}
                    </div>
                    <div className="event-body-content">
                      <p className="tagline-event mb-2 fw-500">
                        February 22, 2025
                      </p>
                      <h2 className="event-title mb-3">News Title</h2>
                      <div className="event-footer">
                        <div className="btn-toolbar">
                          <Link to={`https://www.usatoday.com/story/special/contributor-content/2025/03/21/awakening-genius-in-corporate-culture-a-new-approach-to-conscious-leadership-with-bhupendra-chaudhar/82597928007/`} target="_blank">
                            <button type="button" className="btn btn-default bh-btn-white">
                              Read
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
        <NewsLetter />
      </div>
    </>
  )
}

export default InTheNewsPage
