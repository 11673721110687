import React from 'react'
import TestimonialsSliderView from "../sharedPages/TestimonialsSlider";

// --------------------------------------


const HomePageTestimonialSection = () => {
  return (
    <>
      < section className="bh-testimonials-sec pd-common bg-white exce-home-page-testimonial" >
        <TestimonialsSliderView />
      </section >
    </>
  )
}

export default HomePageTestimonialSection
